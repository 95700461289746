@import '../../styles/customMediaQueries.css';

:root {
  --cartPageWidth: 1100px;
}

.main {
  width: 100%;
  max-width: var(--cartPageWidth);

  margin: 0 auto;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0 24px;
  }
}

.mainPanel {
  margin: 24px 0px;
  padding: 24px;
  flex: 1;

  @media (--viewportMedium) {
    padding: 48px;

    /* Style */
    background-color: #f7f7f7;
    box-shadow: var(--boxShadowLight);
    border-radius: var(--borderRadius);
  }
}

.cartWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: 620px auto;
  }
}

.cartWrapperWithListings {
  align-items: start;
}

.cartDisplay {
}

.cartCard {
  /* Style */
  background-color: var(--matterColorBright);
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);

  margin-bottom: 24px;

  @media (--viewportMedium) {
    background-color: var(--matterColorLight);
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.cartPanel {
  padding: 24px;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadiusLarge);

  @media (--viewportMedium) {
    /* Layout & Position */
    position: sticky;
    align-self: start;
    top: calc(120px + 16px);
  }
}

.cartHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    padding: 16px;
  }
}

.cartBody {
  padding: 0 12px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    padding: 0 16px;
  }
}

.cartFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  padding: 12px;

  @media (--viewportMedium) {
    padding: 16px;
  }
}

.cartButton {
  /* Font */
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  text-transform: uppercase;

  /* Layout */
  margin: 0px;

  & svg {
    height: 20px;
    width: 20px;
  }

  @media (--viewportMedium) {
    max-width: 250px;
  }
}

.panelHeading {
  margin: 0 0 16px;

  @media (--viewportMedium) {
    margin: 0 0 24px;
  }
}

.panelList {
  margin: 0px;
}

.listItem {
  /* Layout */
  display: grid;
  grid-template-columns: 24px auto;
  grid-gap: 24px;
  align-items: center;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.itemHeading {
  /* Font */
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightSemiBold);

  margin: 0px;
}

.itemText {
  /* Font */
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);

  margin: 0px;
}

.itemIcon {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  stroke-width: 1px;
}

.sellerInfo {
  display: flex;
  align-items: center;
}

.sellerDisplayName {
  margin-left: 12px;
}

.sellerProfileLink {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-transform: uppercase;

  /* Animation */
  transition: all 0.1s ease-in-out;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.listingsPanel {
  display: flex;
  flex-direction: column;
}

.listingCard {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 12px 0;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    padding: 16px 0;
  }

  &:last-child {
    border-bottom: 0px;
  }
}

.listingImageWrapper {
  width: 120px;
  height: 100px;
  box-shadow: var(--boxShadow);
  transition: all 0.1s ease-in-out;

  @media (--viewportMedium) {
    width: 140px;
    height: 120px;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.listingImage {
  height: 100%;
  width: 100%;
  border-radius: var(--borderRadius);
  object-fit: cover;
}

.listingInfo {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  padding-left: 24px;

  /* Font */
  line-height: 1.5;
}

.listingLink {
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  /* Animation */
  transition: all 0.1s ease-in-out;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.priceContainer {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0px;
}

.errorContainer,
.loadingContainer,
.emptyCartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
  padding: 24px;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadiusLarge);
}

.errorMessage,
.emptyCartMessage {
  margin: 12px 0 0;

  @media (--viewportMedium) {
    margin: 16px 0 0;
  }
}

.errorMessage {
  max-width: 260px;
  line-height: 1.5;
  text-align: center;
}

.errorIcon,
.loadingIcon,
.emptyCartIcon {
  height: 48px;
  width: 48px;
}

.emptyCartIcon {
  height: 32px;
  width: 32px;

  @media (--viewportMedium) {
    height: 38px;
    width: 38px;
  }
}

.errorIcon {
  fill: var(--failColor);

  & g {
    stroke: var(--failColor);
  }
}

.searchPageLinkContainer {
  margin-top: 24px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0px;
  }
}

.searchPageLink {
  composes: marketplaceSmallFontStyles from global;
  color: var(--matterColorAnti);
  margin: 0px;

  /* Animation */
  transition: all 0.1s ease-in-out;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.bottomContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.removeListingError {
  composes: marketplaceSmallFontStyles from global;
  color: var(--failColor);
  margin: 12px 0 0;
}

.removeIconContainer {
  /* Layout */
  display: flex;
  align-items: center;
  cursor: pointer;

  /* Size */
  width: max-content;
  margin-top: 0;

  /* Font */
  composes: marketplaceSmallFontStyles from global;
  font-size: 13px;
  line-height: 1;
  color: var(--matterColorAnti);
  text-transform: uppercase;

  /* Animation */
  transition: all 0.15s ease-in-out;

  &:hover {
    color: var(--matterColor);

    & .removeIcon g {
      stroke: var(--matterColor);
    }
  }
}

.removeIcon {
  stroke: none;

  /* Size */
  width: 10px;
  height: 10px;

  /* Position */
  margin-right: 5px;

  & g {
    stroke: var(--matterColorAnti);

    /* Animation */
    transition: all 0.15s ease-in-out;
  }
}

.listingCardLoading {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* Style */
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
}

.listingCardLoadingIcon {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 100px;

  @media (--viewportMedium) {
    width: 140px;
    height: 120px;
  }
}

.variants {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 4px;
}

.variant {
  /* Font */
  composes: marketplaceSmallFontStyles from global;
  text-transform: capitalize;

  margin: 0px;
}
