.root {
  flex: 1;
  min-width: 150px;
}

.progressBarWrapper {
  width: 100%;
}

.progressBar {
  width: 100%;
  background-color: var(--matterColorNegative);

  border-radius: 10px;
}

.progressBarFill {
  display: block;
  height: 10px;
  background-color: var(--marketplaceColor);
  border-radius: 10px;
}

.tickCircle {
  height: 24px;
}

.progressBarContainer {
  display: flex;
  align-items: center;

  gap: 10px;
}
