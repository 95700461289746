.root {
  /* Style */
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);

  /* Size */
  width: 100%;
}

.root {
  display: none;

  @media (min-width: 1072px) {
    display: block;
  }
}

.content {
  max-width: fit-content;
  margin: 0 auto;
}

.nav {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: var(--topbarMenuHeight);

  /* Margin */
  margin: 0;
}

.navItem {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  /* Position */
  position: relative;

  /* Font */
  text-align: center;
  white-space: nowrap;

  /* Size */
  height: 100%;

  /* Hover Effect */
  &:hover {
    color: var(--marketplaceColor);

    & .navItemLink {
      color: var(--marketplaceColor);
    }

    & .navItemIcon path {
      stroke: var(--marketplaceColor);
    }
  }
}

@keyframes hoverLine {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.navItemActive {
  position: relative;

  &:before {
    content: '';

    /* Position */
    position: absolute;
    top: calc(100% - 3px);
    left: 0;

    /* Size */
    width: 100%;
    height: 3px;

    /* Style */
    background: var(--marketplaceColor);
    transform-origin: left center;

    /* Animation */
    animation-name: hoverLine;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 0;
  }
}

.brandNavItemActive {
  &:before {
    top: calc(100% - 4px);
  }
}

.navItemWithDropdownActive {
}

.navItemLink {
  /* Font */
  color: var(--marketplaceColor);
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;

  position: relative;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  /* Size */
  height: 100%;
  padding-left: 22px;
  padding-right: 22px;

  /* Effect */
  transition: all var(--transitionStyleButton);
}

.navItemIcon {
  /* Size */
  height: 20px;
  width: 20px;

  /* Style */
  fill: none;

  & path {
    stroke: var(--marketplaceColor);
  }
}

.homeIcon {
  /* Size */
  height: 21px;
  width: 21px;

  /* Spacing */
  margin-bottom: 2px;
}
