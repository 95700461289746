@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.listLabel {
  padding-bottom: 12px;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
}

.label {
  /* Layout */
  display: flex;
  align-items: center;
}
