@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  background-color: var(--matterColorBright);
  position: relative;

  @media (--viewportLarge) {
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 48px;
  }

  @media (--viewportLarge) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;

  @media (--viewportMedium) {
    margin: 0 auto;
    padding: 0 24px 48px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 48px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  display: none;
  margin-bottom: 0;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    flex-basis: 220px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 6px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 24px;
}

.organizationInfo {
}

.organizationDescription {
  composes: marketplaceTinyFontStyles from global;
  line-height: 22px;

  margin-top: 0;
  margin-bottom: 12px;
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  margin: 0;

  @media (--viewportMedium) {
    flex-basis: 165px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;
  white-space: nowrap;

  &:first-child {
    & .heading {
      font-size: 16px;

      &:hover {
        color: var(--matterColor);
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link,
.heading {
  /* Font */
  composes: marketplaceTinyFontStyles from global;

  /* Font override */
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  font-weight: var(--fontWeightSemiBold);
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  margin: 0;

  @media (--viewportMedium) {
    flex-basis: 165px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  margin: 0;

  @media (--viewportMedium) {
    flex-basis: 165px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  margin: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    flex-basis: 109px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.copyright {
  padding: 26px 0 24px;
  border-top: 1px solid var(--matterColorNegative);
}

.copyrightContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.copyrightText {
  composes: marketplaceTinyFontStyles from global;

  margin-top: 0;
  margin-bottom: 0;

  &:nth-child(2) {
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }
}

.heart {
  color: var(--marketplaceColor);
}
