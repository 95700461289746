.root {
  /* Font */
  position: relative;
  display: flex;
  align-items: center;
}

.rootIsOpen .icon path {
  stroke: var(--marketplaceColor);
}

.inputWrapper {
  position: relative;
}

.input {
  /* Position */
  position: absolute;
  top: 0;
  right: 0;

  /* Hide the input by default */
  visibility: hidden;
  pointer-events: none;
  opacity: 0;

  & input {
    /* Layout */
    width: 0;
    padding: 5px 32px 5px 0;

    /* Font */
    font-size: 14px;
    line-height: 24px;

    /* Animation */
    transition: width 0.6s cubic-bezier(0, 0.795, 0, 1);
  }
}

.inputIsOpen {
  /* Show the input */
  visibility: visible;
  pointer-events: all;
  opacity: 1;

  & input {
    width: var(--TopbarDesktopKeywordForm_inputWidth);
  }
}

.icon {
  /* Layout */
  position: relative;
  z-index: 1;

  & path {
    stroke: var(--matterColor);
  }
}
