@import '../../styles/customMediaQueries.css';

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: calc(50% - 12px);
  margin-top: 24px;
}

.button {
  margin-top: 48px;
}
