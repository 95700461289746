.root {
  /* Control */
  & :global(.css-13cymwt-control) {
    /* Style */
    border: 0;
    border-bottom: 2px solid var(--attentionColor);
    border-radius: 0;

    &:hover {
      border-bottom-color: var(--matterColor);
    }
  }
  & :global(.css-1dyz3mf) {
    padding: 2px 0;
  }
  & :global(.css-t3ipsp-control) {
    /* Style */
    border: 0;
    border-bottom: 2px solid var(--matterColor);
    border-radius: 0;
    box-shadow: none;

    /* Size */
    min-height: auto;

    &:hover {
      /* Style */
      border-bottom-color: var(--matterColor);
      border-radius: 0;
      box-shadow: none;
    }
  }

  /* Value */
  & :global(.css-1fdsijx-ValueContainer) {
    padding: 4px 0 2px;
  }
  & :global(.css-3w2yfm-ValueContainer) {
    padding: 4px 0 2px;
  }
  & :global(.css-1dimb5e-singleValue) {
    margin: 0;
  }
  & :global(.css-14pjhf-singleValue) {
    color: var(--matterColor);
    margin: 0;
  }

  /* Input */
  & :global(.css-qbdosj-Input) {
    margin: 0;
    padding: 0;
  }
  & :global(.css-1jqq78o-placeholder) {
    color: var(--matterColorAnti);
    margin: 0;
  }

  /* Input separator */
  & :global(.css-1u9des2-indicatorSeparator) {
    display: none;
  }
  & :global(.css-1xc3v61-indicatorContainer) {
    padding: 0;
  }
  & :global(.css-15lsz6c-indicatorContainer) {
    padding: 0;
  }

  /* Chevron icon */
  & :global(.css-tj5bde-Svg) {
    height: 26px;
    width: 26px;
    margin-right: -4px;

    /* Color */
    fill: var(--matterColor);
  }
  & :global(.css-12a83d4-MultiValueRemove) {
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
    }
  }

  /* Option */
  & :global(.css-d7l1ni-option) {
    color: var(--matterColorLight);
    background-color: var(--marketplaceColorLight);
  }
  & :global(.css-tr4s17-option) {
    background-color: var(--marketplaceColor);
  }
}

.selectSuccess {
  & :global(.css-13cymwt-control),
  & :global(.css-t3ipsp-control) {
    border-color: var(--successColor);
  }
}

.selectError {
  & :global(.css-13cymwt-control),
  & :global(.css-t3ipsp-control) {
    border-color: var(--failColor);
  }
}
