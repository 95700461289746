@import '../../styles/customMediaQueries.css';

.colorFieldSet {
  border: none;
  padding: 0;
  margin: 0;

  & legend {
    padding-bottom: 8px;

    @media (--viewportMedium) {
      padding-bottom: 5px;
    }
  }
}

/* fieldset can't use flexbox hence this wrapper exists */
.color {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  gap: 12px;

  @media (--viewportLarge) {
    grid-template-columns: repeat(7, 1fr);
  }
}

.colorInput {
  display: none;
}

.label {
  /* Layout */
  display: flex;
  align-items: center;
}

.colorBox {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  /* Size */
  height: 36px;
  width: 36px;

  /* Style */
  background-color: transparent;
  border: 2px solid var(--matterColorNegative);
  border-radius: 50%;

  /* Cursor */
  cursor: pointer;

  &:hover {
    border-color: currentColor;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    background-color: currentColor;
    border-radius: 50%;
  }
}

.colorBoxSelected {
  border-color: currentColor;
}
