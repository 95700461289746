@import '../../../styles/customMediaQueries.css';

.quantityField {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.submitButton {
  padding: 0 24px 24px;
  margin-top: 48px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 48px;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
}

.addToCartError {
  composes: finePrint;
  color: var(--failColor);
}

.error {
  composes: h4 from global;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.submitMaskButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.addToCartButton {
  margin-top: 12px;
}

.addToCartMaskButton {
  composes: button buttonFont buttonText buttonBordersSecondary buttonColorsSecondary from global;

  margin-top: 12px;
}

.colorButtons {
  padding: 0 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sizeField {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
