@keyframes dropdownLine {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.root {
  /* Position */
  position: absolute;
  top: var(--topbarMenuHeight);
  left: 0;
  z-index: 99;

  /* Font */
  text-align: left;

  /* Size */
  padding: 18px 0;
  min-width: 200px;

  /* Style */
  background: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);
  border-left: 1px solid var(--matterColorNegative);
  border-right: 1px solid var(--matterColorNegative);

  &:before {
    content: '';

    /* Position */
    position: absolute;
    bottom: 100%;
    left: 0;

    /* Size */
    width: 100%;
    height: 3px;

    /* Style */
    background: var(--marketplaceColor);
    transform-origin: left center;

    /* Animation */
    animation-name: dropdownLine;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 0;
  }
}

.brandRoot {
  /* Position */
  position: fixed;
  top: calc(var(--topbarMenuHeight) + var(--topbarHeightDesktop));

  /* Size */
  width: 100vw;
  height: 100vh;
  max-height: calc(100vh + calc(var(--topbarMenuHeight) + var(--topbarHeightDesktop)));

  /* Scroll */
  overflow: scroll;
  overscroll-behavior: contain;
}

.dropdownGridList {
  /* Layout */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 48px;

  /* Padding */
  padding-left: 96px;
  padding-right: 96px;

  @media (--viewportLarge) {
    padding-left: 124px;
    padding-right: 124px;
  }
}

.dropdownList {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.dropdownListItem {
  /* Layout */
  display: flex;
  align-items: center;
  position: relative;

  /* Font */
  font-size: 14px;
  line-height: 24px;

  /* Size */
  width: 100%;

  /* Hover Effect */
  &:hover {
    & .dropdownListItemArrow {
      left: 4px;
    }

    & .itemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }
}

.dropdownListItemType {
  /* Font */
  color: var(--matterColorAnti);
  text-transform: capitalize;
  text-decoration: none !important;

  /* Size */
  padding: 6px 24px;
  width: 100%;
}

.dropdownListItemLink {
  /* Font */
  color: var(--matterColor);
  text-transform: capitalize;
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  /* Size */
  padding: 6px 24px;
  width: 100%;
}

.dropdownListItemArrow {
  /* Position */
  position: relative;
  left: 0;

  /* Color */
  stroke: none;

  /* Effect */
  transition: 0.15s ease-in-out;
}

.itemBorder {
  /* Position */
  position: absolute;
  top: 0px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;

  /* Effect */
  transition: width var(--transitionStyleButton);
}

/* Add this CSS to ensure buttons are visible and clickable */
.sortBtn {
  /* Ensure the button is visible and clickable */
  display: block; /* Ensure the button is displayed */
  width: 100%; /* Make the button take full width */
  padding: 4px; /* Add padding for better click area */
  background-color: var(--buttonBackgroundColor, #f0f0f0); /* Set a background color */
  border: none; /* Remove default border */
  cursor: pointer; /* Change cursor to pointer */
  text-align: center; /* Center the text */
  transition: background-color 0.3s; /* Add a transition for hover effect */
}

.sortBtn:hover {
  background-color: var(--buttonHoverColor, #e0e0e0); /* Change background on hover */


    & .dropdownListItemArrow {
      left: 0;
    }

}