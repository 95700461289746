@import '../../styles/customMediaQueries.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  composes: marketplaceModalRootStyles from global;
  composes: defaultBackgroundImageForDesktop from global;

  background-image: none;
  background-color: var(--marketplaceColorLight);
}

.content {
  composes: marketplaceModalBaseStyles from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.title {
  composes: marketplaceModalTitleStyles from global;
}

.textWithBottomMargin {
  margin-top: 0;
}

.textWithoutMargin {
  margin-top: 0;
  margin-bottom: 0;
}
