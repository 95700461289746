@import '../../styles/customMediaQueries.css';

.root {
  & p {
    font-weight: var(--fontWeightRegular);

    margin-top: 18px;
    margin-bottom: 18px;

    @media (--viewportMedium) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  & h3 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 24px 0 12px;
  }
  & ul {
    list-style: initial;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  & li {
    font-weight: var(--fontWeightRegular);
  }
}

.lastUpdated {
  composes: marketplaceBodyFontStyles from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
}
