.root {
  & path {
    transition: var(--transitionStyleButton);
  }

  &:hover {
    & path {
      fill: var(--marketplaceColor);
    }
  }
}
