.root {
  /* Position */
  position: absolute;
  left: 100%;
  top: 0;
}

.subItemWrapper {
  position: fixed;

  /* Font */
  text-align: left;

  /* Size */
  padding: 18px 0;
  min-width: 200px;

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
}

.dropdownSubItemsList {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.dropdownSubItemsListItem {
  /* Font */
  font-size: 14px;
  line-height: 24px;

  /* Size */
  position: relative;
  width: 100%;

  &:hover {
    /* Sub Item Border */
    & .subItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }
}

.dropdownSubItemsListItemLink {
  /* Font */
  color: var(--matterColor);
  text-transform: capitalize;
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  /* Size */
  padding: 6px 24px;
  width: 100%;
}

.subItemBorder {
  /* Position */
  position: absolute;
  top: 0px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;

  /* Effect */
  transition: width var(--transitionStyleButton);
}
