@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  max-width: 100px;
  height: auto;

  margin: 0;
}
