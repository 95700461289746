@import '../../styles//customMediaQueries.css';

:root {
  --tooltipWidth: 250px;
}

.root {
  /* Font */
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  line-height: 20px;

  margin-top: 0;
  margin-bottom: 0;
}

.symbol {
  /* Font */
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
}

.tooltip {
  max-width: var(--tooltipWidth);
}
