.accordionSubItem {
  position: relative;
  margin-left: 12px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.accordionSubItemLink {
  display: block;
  position: relative;
  padding: 12px 0;
  margin-left: 12px;
  border-bottom: 1px solid var(--matterColorNegative);
  font-size: 16px;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);

  &:last-child {
    border-bottom: 0;
  }
}

.accordionSubItemNav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--matterColorNegative);
  font-size: 16px;

  &:last-child {
    border-bottom: 0;
  }
}

.subItemLabel {
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColor);
  transition: all 0.25s ease-in-out;
}

.plusIcon {
  position: relative;
  height: 10px;
  width: 10px;

  &:after,
  &:before {
    position: absolute;
    content: '';
    background-color: var(--matterColor);
    transition: transform 0.25s cubic-bezier(0.75, 0.02, 0.5, 1),
      color 0.25s cubic-bezier(0.75, 0.02, 0.5, 1);
  }

  &:before {
    height: 11.5px;
    width: 2px;
    top: 0;
    left: 50%;
  }

  &:after {
    width: 11.5px;
    height: 2px;
    left: 0;
    top: 50%;
  }
}

.activeAccordionSubItem {
  .subItemLabel {
    color: var(--marketplaceColor);
  }

  .plusIcon {
    &:before {
      transform: rotate(90deg);
      background-color: var(--marketplaceColor);
    }

    &:after {
      transform: rotate(1turn);
      background-color: var(--marketplaceColor);
    }
  }
}
