.accordionItem {
  position: relative;
  width: 100%;

  &:last-child {
    border-bottom: 0;

    & .accordionItemNav {
      border-bottom: 0;
    }
  }
}

.accordionItemNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--matterColorNegative);
  color: var(--marketplaceColor);
  font-weight: 600;
}

.itemLabel {
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColor);
  transition: all 0.25s ease-in-out;
}

.activeAccordionItem .itemLabel {
  color: var(--marketplaceColor);
}

.activeAccordionItem .arrow {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);

  /* Animation */
  transform: rotate(90deg);
}

.arrow {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);

  /* Animation */
  transition: all 0.25s ease-in-out;
}
